import React from "react"
import Layout from "./../../../components/layouts/Request"
import Seo from "./../../../components/layouts/Seo"
import RequestLeftCol from "./../../../components/layouts/partials/pages/request/RequestLeftCol"
import RequestRightCol from "./../../../components/layouts/partials/pages/request/RequestRightCol"
import Wizard from "./../../../components/forms/Wizard"
import {
  AnlageForm,
  AnlageFoerderung,
} from "./../../../components/layouts/partials/pages/request/Geldanlage"
import FormMainData from "./../../../components/layouts/partials/pages/request/MainData"

export default function Page() {
  return (
    <Layout>
      <Seo
        title="Geldanlagestrategie anfragen"
        description="Wir finden die passende Geldanlagestrategie für Dich."
        path="/geldanlage/allgemeine-geldanlage/anfragen/"
      />
      <section>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-8/12 lg:order-2 max-w-screen-lg p-8 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
                Allgemeine Geldanlagestrategie anfragen
              </h1>
              <form
                action="https://formbackend.dierda.de/anfrage.php"
                method="POST"
              >
                <input
                  type="hidden"
                  name="Anfrage"
                  value="Allgemeine Geldanlage"
                />

                <Wizard>
                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        Wie möchtest Du Dein Geld anlegen?
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Klicke kurz an, wie Du Dein Geld anlegen willst. Diese
                        Informationen sind wichtig, damit wir ungefähr wissen,
                        was Du benötigst.
                      </p>
                    </div>
                    <AnlageForm />
                  </div>
                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        Sind folgende Optionen für Dich relevant?
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Klicke kurz an, ob die folgenden Optionen für Dich
                        relevant sind. Diese Informationen sind wichtig, damit
                        wir ungefähr wissen, was Du benötigst.
                      </p>
                    </div>
                    <AnlageFoerderung />
                  </div>
                  <FormMainData />
                </Wizard>
              </form>
            </RequestRightCol>
          </div>
          <div className="w-full lg:w-4/12 lg:order-1 bg-gray-100 p-8 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
